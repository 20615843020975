import { useState } from "react";

import ReactionCardContextMenu from "components/feed/ReactionCardContextMenu";
import Response from "components/ui/Response";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import useReply from "lib/motorcade/hooks/useReply";
import {
  AlertCallback,
  CcoReaction,
  CommentReaction,
  FeedActivity,
  ReplyReaction,
} from "lib/motorcade/types";
import { parseReactions } from "lib/motorcade/utils/state";

import ActivityCardCommentInput from "./ActivityCardCommentInput";

type Props = {
  activity: FeedActivity;
  comment: CommentReaction;
  reply: ReplyReaction;
};

export default function ActivityCardReply({ activity, comment, reply }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const { updateReply, updateReplyLoading, addReaction, removeReaction } =
    useReply({
      activity,
      comment,
      reply,
      alertCallback,
    });
  const { id: myId } = useMe();

  const reactions = parseReactions({ activity: reply, myId });

  const handleReactionClick = (kind: CcoReaction) => {
    if (kind === reactions.own?.kind) removeReaction();
    else addReaction(kind);
  };

  if (isEditing) {
    return (
      <ActivityCardCommentInput
        open
        initialData={reply?.data}
        isSubmitting={updateReplyLoading}
        type="reply"
        onClose={() => setIsEditing(false)}
        onSubmit={async (input) => {
          await updateReply(input);
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <Response
      authorId={reply.user_id}
      avatar={reply.user.data.avatar}
      body={reply.data.body}
      date={reply.created_at}
      handleReactionClick={handleReactionClick}
      headerAction={
        <ReactionCardContextMenu
          reaction={reply}
          onEdit={() => setIsEditing(true)}
        />
      }
      id={reply.id}
      isEdited={reply.updated_at !== reply.created_at}
      isMemberLeader={reply.user.data.isMemberLeader}
      joinedAt={reply.user.data.joinedAt}
      latestReactions={reactions.latest}
      mediaType={reply.data.mediaType}
      mediaUrl={reply.data.mediaUrl}
      name={reply.user.data.name}
      reactionCounts={reactions.counts}
      replyCount={reply.children_counts.reply}
      selectedReaction={reactions.own?.kind}
    />
  );
}
