import { Box, Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";

import ReactionCardContextMenu from "components/feed/ReactionCardContextMenu";
import Response from "components/ui/Response";
import ResponseSkeleton from "components/ui/ResponseSkeleton";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import useComment from "lib/motorcade/hooks/useComment";
import useReply from "lib/motorcade/hooks/useReply";
import {
  AlertCallback,
  CcoReaction,
  CommentReaction,
  FeedActivity,
  ReplyReaction,
} from "lib/motorcade/types";
import { parseReactions } from "lib/motorcade/utils/state";

import ActivityCardCommentInput from "./ActivityCardCommentInput";
import ActivityCardReply from "./ActivityCardReply";

type Props = {
  activity: FeedActivity;
  comment: CommentReaction;
};

export default function ActivityCardComment({ activity, comment }: Props) {
  const [isReplyInputOpen, setIsReplyInputOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const {
    addReaction,
    loadMoreReplies,
    loadingMoreReplies,
    removeReaction,
    updateComment,
    updateCommentLoading,
  } = useComment({
    activity,
    comment,
    alertCallback,
  });
  const { addReply, addReplyLoading } = useReply({
    activity,
    comment,
    alertCallback,
  });
  const { id: myId } = useMe();
  const { children_counts, latest_children } = comment;

  const reactions = parseReactions({ activity: comment, myId });

  const replies =
    latest_children.reply?.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    ) || [];

  const handleReactionClick = (kind: CcoReaction) => {
    if (kind === reactions.own?.kind) removeReaction();
    else addReaction(kind);
  };

  return (
    <>
      {!isEditing && !updateCommentLoading && (
        <Response
          authorId={comment.user_id}
          avatar={comment.user.data.avatar}
          body={comment.data.body}
          date={comment.created_at}
          handleReactionClick={handleReactionClick}
          handleReplyClick={() => setIsReplyInputOpen((prev) => !prev)}
          headerAction={
            <ReactionCardContextMenu
              reaction={comment}
              onEdit={() => setIsEditing(true)}
            />
          }
          id={comment.id}
          isEdited={comment.updated_at !== comment.created_at}
          isMemberLeader={comment.user.data.isMemberLeader}
          joinedAt={comment.user.data.joinedAt}
          latestReactions={reactions.latest}
          mediaType={comment.data.mediaType}
          mediaUrl={comment.data.mediaUrl}
          name={comment.user.data.name}
          reactionCounts={reactions.counts}
          replyCount={comment.children_counts.reply}
          selectedReaction={reactions.own?.kind}
        >
          <ActivityCardCommentInput
            isSubmitting={addReplyLoading}
            open={isReplyInputOpen}
            type="reply"
            onSubmit={async (input) => {
              await addReply(input);
              setIsReplyInputOpen(false);
            }}
          />

          {children_counts.reply > replies.length && (
            <Button
              className="pendo_web-post-view-more-replies"
              sx={{
                alignSelf: "flex-start",
                color: (theme) => theme.palette.text.secondary,
              }}
              variant="link"
              onClick={() => loadMoreReplies()}
            >
              View more replies
            </Button>
          )}
        </Response>
      )}

      {(isEditing || updateCommentLoading) && (
        <ActivityCardCommentInput
          open
          initialData={comment?.data}
          isSubmitting={updateCommentLoading}
          type="comment"
          onClose={() => setIsEditing(false)}
          onSubmit={async (input) => {
            await updateComment(input);
            setIsEditing(false);
          }}
        />
      )}

      {loadingMoreReplies && (
        <Box pl={6.5}>
          <ResponseSkeleton />
          <ResponseSkeleton />
        </Box>
      )}

      {replies?.length > 0 && (
        <Stack pl={6.5} spacing={1}>
          {replies?.map((reply: ReplyReaction) => (
            <ActivityCardReply
              key={reply.id}
              activity={activity}
              comment={comment}
              reply={reply}
            />
          ))}
        </Stack>
      )}
    </>
  );
}
