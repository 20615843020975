import { Block, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { isAfter, startOfDay } from "date-fns";
import { useRef, useState } from "react";

import ActivityCardContextMenu from "components/feed/FeedCard/base/ActivityCardContextMenu";
import Answer from "components/feed/FeedCard/cards/GroupEpQuestionCard/Answer";
import AnswerInput from "components/feed/FeedCard/cards/GroupEpQuestionCard/AnswerInput";
import AnswerLoading from "components/feed/FeedCard/cards/GroupEpQuestionCard/AnswerLoading";
import JoinGroupCta from "components/feed/FeedCard/cards/GroupEpQuestionCard/JoinGroupCta";
import ExpertPanelQuote from "components/icons/ExpertPanelQuote";
import Avatar from "components/shared/Avatar";
import Truncate from "components/shared/Truncate";
import useIdentity from "hooks/useIdentity";
import useJoinGroup from "hooks/useJoinGroup";
import useMe from "hooks/useMe";
import useMyGroups from "hooks/useMyGroups";
import { useOrientationTasks } from "hooks/useOrientationTasks";
import { useApp } from "lib/common/appProvider";
import pluralize from "lib/common/pluralize";
import useGroupEp from "lib/motorcade/hooks/useGroupEp";
import { AlertCallback, FeedActivity } from "lib/motorcade/types";
import { formatActivityTime } from "lib/motorcade/utils/date";
import trackEvent from "lib/segment/trackEvent";

type Props = {
  activity: FeedActivity & {
    verb: "group_expertpanel";
  };
  isPinned?: boolean;
  queryKey?: string;
};

export default function GroupEPQuestionCard({
  activity,
  isPinned,
  queryKey,
}: Props) {
  const { latest_reactions, own_reactions, reaction_counts, object, groupRef } =
    activity;
  const { question, isEnded, endAt, maxAnswerLength, questionId } =
    object?.data || {};
  const answers = latest_reactions?.answer || [];
  const group = groupRef?.data;

  const snackbarRef = useApp("snackbarRef");
  const alertCallback: AlertCallback = ({ message, type, title }) => {
    snackbarRef?.current?.callSnackbar(message, type, title);
  };

  const { communityCode: community_code, role, userId } = useIdentity();
  const { email } = useMe();
  const { myGroups } = useMyGroups();
  const answerInputRef = useRef(null);
  const { publishingWebsite } = useApp("site");
  const { markStepAsCompleted, isStepCompleted } = useOrientationTasks();
  const [showAnswerInput, setShowAnswerInput] = useState(false);
  const { handleJoin, isJoining } = useJoinGroup({ id: groupRef?.id });
  const { hasMoreAnswers, loadingMoreAnswers, loadMoreAnswers, addAnswer } =
    useGroupEp(activity, alertCallback, queryKey);

  const isFollower = myGroups?.some(({ id }) => id === groupRef?.id);
  const isAdmin = role === "ADMIN";
  const answerCount = reaction_counts?.answer || 0;
  const hasAnswered = own_reactions?.answer?.length > 0;
  const othersText =
    answerCount > 1 &&
    `and ${answerCount - 1} ${pluralize("other", answerCount - 1)}`;

  const isQuestionClosed =
    isEnded || (endAt && isAfter(startOfDay(new Date()), new Date(endAt)));

  const showJoinGroup =
    !isAdmin &&
    !isFollower &&
    (!isQuestionClosed || (isQuestionClosed && answers?.length > 0));

  const sortedAnswers = answers?.toSorted((a, b) =>
    a.created_at < b.created_at ? -1 : 1
  );

  const traits = {
    community_code,
    role,
    userId,
    email,
    expert_panel_id: questionId,
  };

  const pendoClass = isPinned
    ? "pendo_groups-pinned-eproundup"
    : "pendo_desktop-post-group-ep";

  const handleAnswerClick = () => {
    trackEvent({
      category: "GroupFeed",
      label: group?.name,
      name: "GroupEPAnswer",
      traits,
    });
    setShowAnswerInput(true);
    setTimeout(() => {
      answerInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  };

  const handleJoinClick = async () => {
    trackEvent({
      category: "GroupFeed",
      label: group?.name,
      name: "GroupEPJoinGroup",
      traits,
    });
    handleJoin();
  };

  const handleViewMoreAnswersClick = () => {
    trackEvent({
      category: "GroupFeed",
      label: group?.name,
      name: "GroupEPViewAllAnswers",
      traits,
    });
    loadMoreAnswers();
  };

  const handleSubmitAnswer = async (input: { answer: string }) => {
    trackEvent({
      category: "GroupFeed",
      label: group?.name,
      name: "GroupEPAnswerSubmit",
      traits,
    });
    addAnswer(input);
    setShowAnswerInput(false);
    if (!isStepCompleted("expertPanel")) {
      markStepAsCompleted("expertPanel");
    }
  };

  return (
    <Card className={pendoClass}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        px={2}
        py={1.4}
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
        })}
      >
        <Stack alignItems="center" direction="row">
          <ExpertPanelQuote
            sx={{
              color: (theme: Theme) => theme.palette.primary.contrastText,
            }}
          />

          <Typography
            color={(theme) => theme.palette.primary.contrastText}
            component="div"
            sx={{ ml: 2 }}
            variant="body1Semibold"
          >
            Expert Panel
          </Typography>

          <Tooltip
            arrow
            placement="top"
            title={`Answer our Expert Panel questions and you could get your expert insights featured together with your peers in an article${publishingWebsite !== "" ? ` on ${publishingWebsite}` : ""}.`}
          >
            <InfoOutlined
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                ml: 1,
              }}
            />
          </Tooltip>
        </Stack>

        <ActivityCardContextMenu activity={activity} iconColor="primary" />
      </Box>

      <Box
        p={2}
        pb={answers?.length > 0 && !isFollower ? 4.5 : 2}
        position="relative"
      >
        <Truncate maxLines={4}>
          <Typography variant="h3">{question}</Typography>
        </Truncate>

        <Stack alignItems="center" direction="row" mt={2} spacing={1}>
          <Avatar alt={group?.name} src={group?.image} />
          <Stack>
            <Typography variant="body2">{group?.name}</Typography>
            <Typography color="textSecondary" variant="caption">
              {formatActivityTime(object.created_at)}
            </Typography>
          </Stack>
        </Stack>

        <Divider sx={{ mt: 2 }} />

        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          py="8px"
        >
          {hasAnswered && (
            <Typography variant="body2">
              <Typography component="span" variant="body2Semibold">
                You {othersText}
              </Typography>{" "}
              have answered.{" "}
              {!isQuestionClosed ? "Only one answer is permitted." : ""}
            </Typography>
          )}

          {!isQuestionClosed && !hasAnswered && (
            <>
              <Typography variant="body2">
                <Typography component="span" variant="body2Semibold">
                  {answerCount} {pluralize("member", answerCount)}
                </Typography>{" "}
                {answerCount === 1 ? "has" : "have"} answered.
              </Typography>
              <Button
                className="pendo_desktop-groupsep-answer"
                disabled={!isFollower}
                variant="contained"
                onClick={handleAnswerClick}
              >
                Answer
              </Button>
            </>
          )}

          {isQuestionClosed && (
            <Stack alignItems="center" direction="row" spacing="6px">
              <Block />
              <Typography variant="body2">
                This question is now closed.
              </Typography>
            </Stack>
          )}
        </Stack>

        <Divider />

        {isFollower && hasMoreAnswers && (
          <Button
            sx={(theme) => ({
              ...theme.typography.subtitle2,
              textTransform: "none",
              color: theme.palette.text.secondary,
              marginTop: theme.spacing(2),

              "&:hover": {
                backgroundColor: "transparent",
              },
              "&.small-label": {
                "& $label": {
                  ...theme.typography.subtitle2,
                },
              },
            })}
            onClick={handleViewMoreAnswersClick}
          >
            View all answers
          </Button>
        )}

        {showJoinGroup && (
          <Box sx={{ display: "flex", height: "34px", mt: 2 }}>
            <JoinGroupCta
              answerCount={answerCount}
              handleJoinClick={handleJoinClick}
              loading={isJoining}
              open={!isQuestionClosed}
            />
          </Box>
        )}

        {loadingMoreAnswers && <AnswerLoading />}

        {answers?.length > 0 && (
          <>
            <Box position="relative">
              {sortedAnswers.map((answer) => (
                <Answer key={answer.id} answer={answer} />
              ))}

              {!isFollower && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "-36px",
                    width: "100%",
                    height: "calc(100% + 36px)",
                    background:
                      "linear-gradient(0deg, #FFFFFF 0%, rgba(255,255,255,0) 100%)",
                  }}
                />
              )}
            </Box>

            {!isFollower && !isAdmin && (
              <Box
                sx={{
                  transform: "translateX(-50%)",
                  whiteSpace: "nowrap",
                  bottom: "18px",
                  left: "50%",
                  position: "absolute",
                }}
              >
                <JoinGroupCta
                  answerCount={answerCount}
                  handleJoinClick={handleJoinClick}
                  loading={isJoining}
                  open={!isQuestionClosed}
                />
              </Box>
            )}
          </>
        )}

        <div ref={answerInputRef}>
          {showAnswerInput && !hasAnswered && (
            <AnswerInput
              inputRef={answerInputRef}
              maxChars={maxAnswerLength}
              onCancel={() => setShowAnswerInput(false)}
              onSubmit={handleSubmitAnswer}
            />
          )}
        </div>
      </Box>
    </Card>
  );
}
