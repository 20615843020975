import { Reply } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import { ReactNode } from "react";

import Reactions from "components/feed/FeedCard/base/reactions";
import Markdown from "components/markdown/Markdown";
import ProfilePopoverWrapper from "components/profile/ProfilePopoverWrapper";
import Avatar from "components/shared/Avatar";
import Badge from "components/shared/Badge";
import Truncate from "components/shared/Truncate";
import formatDate from "lib/date/formatDate";
import {
  CcoReaction,
  LatestReactions,
  ReactionCounts,
} from "lib/motorcade/types";

import DotSeparator from "./DotSeparator";
import MediaAttachmentDisplay from "./MediaAttachmentDisplay";
import ReactionButton from "./ReactionButton";

type Props = {
  id: string;
  authorId: string;
  avatar: string;
  body: string;
  children?: ReactNode;
  date: string;
  handleReactionClick: (kind: CcoReaction) => void;
  handleReplyClick?: () => void;
  latestReactions: LatestReactions;
  mediaType: "image" | "video";
  mediaUrl: string;
  name: string;
  reactionCounts: ReactionCounts;
  replyCount: number;
  selectedReaction: string;
  headerAction: ReactNode;
  isEdited: boolean;
  joinedAt: string;
  isMemberLeader: boolean;
};

export default function Response({
  authorId,
  avatar,
  body,
  children,
  date,
  handleReactionClick,
  handleReplyClick,
  headerAction,
  id,
  isEdited,
  latestReactions,
  mediaType,
  mediaUrl,
  name,
  reactionCounts,
  replyCount,
  selectedReaction,
  joinedAt,
  isMemberLeader,
}: Props) {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const isNewMember = joinedAt
    ? differenceInDays(new Date(), new Date(joinedAt)) < 30
    : false;
  return (
    <Box
      className={id.includes("pending") ? "pending" : ""}
      display="flex"
      gap={1.5}
    >
      {ProfilePopoverWrapper(<Avatar alt={name} src={avatar} />, authorId)}
      <Stack spacing={1} width="100%">
        <Stack
          borderRadius={1}
          p={2}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            "& .header-action": {
              visibility: "hidden",
            },
            "&:hover .header-action": {
              visibility: "visible",
            },
          }}
          width="100%"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <div>
              {ProfilePopoverWrapper(
                <Box
                  alignItems={isXs ? "start" : "center"}
                  display="flex"
                  flexDirection={isXs ? "column" : "row"}
                  gap={isXs ? 0 : 1}
                >
                  {(isMemberLeader || isNewMember) && (
                    <Badge
                      sx={{ mb: isXs ? "3px" : 0 }}
                      type={isMemberLeader ? "leader" : "new"}
                    />
                  )}
                  <Typography variant="body2Semibold">{name}</Typography>
                </Box>,
                authorId
              )}
              <Box alignItems="center" display="flex" gap={1}>
                <Typography color="textSecondary" variant="caption">
                  {formatDate(date, "comment")}
                </Typography>
                {isEdited && (
                  <>
                    <DotSeparator />
                    <Typography color="textSecondary" variant="caption">
                      Edited
                    </Typography>
                  </>
                )}
              </Box>
            </div>

            <div className="header-action">{headerAction}</div>
          </Box>

          <Box mt={0.5}>
            <Truncate buttonBackgroundColor="#f4f4f4" maxLines={3}>
              <Typography variant="body2">
                <Markdown markdown={body} />
              </Typography>
            </Truncate>
          </Box>

          <MediaAttachmentDisplay image={mediaUrl} type={mediaType} />

          <Reactions
            commentCount={replyCount}
            latestReactions={latestReactions}
            reactionCounts={reactionCounts}
          />

          <Box display="flex" gap={1}>
            <ReactionButton
              pendoClassName={
                handleReplyClick
                  ? "pendo_button-comment-react"
                  : "pendo_button-reply-react"
              }
              selectedReaction={selectedReaction}
              size="medium"
              onClick={handleReactionClick}
            />
            {handleReplyClick && (
              <Button
                className="pendo_web-comment-reply"
                size="medium"
                startIcon={<Reply />}
                onClick={handleReplyClick}
              >
                Reply
              </Button>
            )}
          </Box>
        </Stack>

        {children}
      </Stack>
    </Box>
  );
}
